.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .paper {
    padding: 20px;
    margin-top: 20px;
    text-align: center;
  }
  
  .fetch-button {
    margin-top: 20px;
  }
  
  .results {
    margin-top: 20px;
  }
  
  .result-item {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .button-container {
    margin-top: 20px;
  }
  
  